* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  background: rgb(14, 14, 14);
}

.logo {
  width: 170px;
  margin-bottom: 100px;
}

h1,
h2,
h3,
h4 {
  font-family: "Arupala Grotesk";
}

a,
p, ul, li {
  font-family: arial;
}

li {
  list-style-position: inside;
}

a{
  color: initial;
  text-decoration: none;
}