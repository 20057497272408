.privacy-policy {
  color: white;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.privacy-policy h1 {
  margin-bottom: 50px;
}

.privacy-policy h2 {
  margin-bottom: 10px;
}

.text-block {
  margin-bottom: 25px;
}
