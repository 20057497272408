
main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding-left: 2%;
    padding-right: 2%;
    height: 100%;
  }
  
  .main-content {
    width: 100%;
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  
  main h1 {
    font-family: "arupala grotesk";
    font-size: clamp(2rem, 2.5vw, 4rem);
    line-height: 1;
    color: white;
    margin-bottom: 10px;
  }
  
  main p {
    color: rgb(175, 175, 175);
    font-family: arial;
    font-weight: 100;
    font-size: 16px;
  }
  

  
  input {
    height: 50px;
    width: 100%;
    max-width: 300px;
    margin-top: 30px;
    border-radius: 3px;
    padding-left: 12px;
    border: solid 1px rgb(160, 160, 160);
    background: none;
    color: white;
  }
  
  .test {
    display: none;
  }
  
  button {
    width: 100%;
    max-width: 300px;
    height: 50px;
    background: #00ffb7;
    margin-top: 10px;
    border: 0;
    border-radius: 3px;
    font-weight: bolder;
    font-size: 15px;
    text-transform: uppercase;
    color: black;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  
  @media only screen and (min-width: 600px) {
    button:hover {
      transform: scale(0.98);
    }
  }
  
  .anna-container {
    display: flex;
    align-items: center;
    margin-top: 50px;
    cursor: pointer;
  }
  
  .anna-container p {
    color: white;
    font-weight: bold;
  }
  
  .anna {
    height: 30px;
    width: 30px;
    background: #00ffb7;
    border-radius: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: glow 2s infinite;
  }
  
  .privacy-policy-link {
    margin-bottom: 50px;
    margin-top: 25px;
    color: white;
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0px 0px 20px 1px rgba(45, 255, 196, 0.9);
    }
  
    50% {
      box-shadow: 0px 0px 20px 5px rgba(45, 255, 196, 0.9);
    }
  
    100% {
      box-shadow: 0px 0px 20px 1px rgba(45, 255, 196, 0.9);
    }
  }
  
  .fa-brands {
    color: white;
    font-size: 20px;
    margin-right: 10px;
  }
  
  .footer{
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }

  .success{
    color: white;
  }